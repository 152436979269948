import * as React from "react"
import CaseStudyTemplate from "../components/case-study-template";
import { StaticImage } from "gatsby-plugin-image";

const content = {
	title: `Subway`,
	heading: 'Sink-a-Sub Promotion.',
	emphasisedText: 'A massive promotion across Australia and New Zealand saw millions of winners',
	text: [
		'Via Wunderman Thompson, we helped Subway launch a massive trans-Tasman promotion. The promotion featured a gamified entry mechanic, transforming the way customers engage with the brand’s iconic subs.', 
		'As users play, the game calls to the KO promotion system to randomly allocate millions of prizes instantly. With a qualifying purchase, customers received a unique code that could be entered at the Sink-A-Sub site for a chance to play the battleship style game and win!'
	],
	numbers: {
		Entrants: '3000',
		Winners: '500',
		'In prizes': '$1M',
	},
};

export default function Subway() {
	return (
		<CaseStudyTemplate 
			heading={content.heading}
			superHeading={content.title}
			image={<StaticImage alt="Hero image" src="../images/Subway@3x.png" width={500} height={500} />}
			content={{
				title: content.emphasisedText,
				text: content.text,
			}}
			numbers={content.numbers}
		/>
	)
}
